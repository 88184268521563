@import '~leaflet/dist/leaflet.css';

.venue-form {
  display:flex; 
  flex-direction:column; 
  height:100%; 
  width:50%;
  margin-right: 16px;
}

.venue-form-item {
  padding: 8px;
  margin: 4px;
  border-radius: 4px;
  font: inherit;
  resize: none;
  border-color: rgb(204, 204, 204);
  border-width: 1px;
}

.venue-form-submit-button {
  font: inherit;
  border-radius: 4px;
  background-color: lightgray;
  color: black;
  margin: 4px 4px 0px 4px
}

.helper-text {
  margin: 0px 0px 4px 8px;
  font:inherit;
  font-size: small;
  color:rgb(204, 0, 0);
}