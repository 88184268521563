.event-form-venue-map {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-style: solid;
    border-width: 1px;
    border-radius: 4px;
    border-color: lightgray;
  }