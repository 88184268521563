.main {
    position: relative;
    display: block;
    align-items: center;
    min-height: 72px;
    border: 2px solid;
    border-radius: 9px;
    padding-left: 0.1em;
    margin: 1em;
}

.main_collapsed {
    position: relative;
    display: block;
    align-items: center;
    min-height: 72px;
    padding-left: 0.1em;
    margin: 1em;
}

.header {
    display: flex;
    position: relative;
    border: 0px solid;
    border-bottom: 2px solid;
    align-items: center;
    min-height: 0;
    flex-direction: row-reverse;

}

.header_collapsed {
    display: flex;
    position: relative;
    border: 2px solid;
    align-items: center;
    min-height: 0;
    flex-direction: row-reverse;
    border-radius: 9px;
}
.title {
    flex: auto;
    font-size: 20px;
    margin: 0.25em;
}

.icon {
    position: absolute;
    font-size: 24px;
    box-sizing: border-box;
    flex: auto;
    margin-right: 12px;
}

.content_container {
    padding: 0.5em;
}
