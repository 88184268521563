.eventTime {
    margin-bottom: 0;
    font-size: 1.25rem;
    line-height: 1.25rem;
    font-weight: 600;
    min-height: 24px;
    display: block;
}

.eventName {
    margin-bottom: 8px;
    font-size: 3.25rem;
    line-height: 4rem;
    color: #1e0a3c;
    font-weight: 800;
    word-break: break-word;
}

.eventHost {
    color: #1e0a3c;
    font-weight: 600;
}