.event-form-venue-map 
{
  height: 15em;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: lightgray;
}

img.titan-logo
{
  display: block;
  max-width:100%;
  max-height:100%;
  width: 1760px;
  height: 990px;
}

.event-grid {
  display: grid;
  grid-template-columns : [main] 60% [side] 40% [end];
  column-gap: 10px;
  justify-items: stretch;
  align-items: stretch;
}

.event-main {
  grid-column-start: main;
  grid-column-end: side;

}

.event-side {
  grid-column-start: side;
  grid-column-end: end;
}